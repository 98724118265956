export default {
  data() {
    return {
      rulesList: [
        {
          name: "個人賽 比賽章程 ",
          url:
            "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/85508c29c3dfe2dd7b26029007199263.pdf",
        },
        {
          name: "團體赛 比賽章程 ",
          url:
            "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/86869a9fa9949029f2aebe264422949c.pdf",
        },
      ],
      //  app下载
      appUrl: "https://app.chineserd.com",
      // 赛事质询
      whatUrl: "https://wa.me/85291496563",
      // h5banner
      h5BannerUrl: require("@/assets/image/hodgepodge/h5banner.png"),
      // pcbanner
      pcBannerUrl: require("@/assets/image/hodgepodge/pcbanner.png"),
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  mounted() {
    
  },
  methods: {},
};
